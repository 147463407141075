// src/data.js
const data = [
    {
      id: 1,
      title: 'All Products',
      content: 'Product Category',
      path: 'https://shop.aeronoma.com/',
    },
    {
      id: 2,
      title: 'STEM kits',
      content: 'Education, stem kits, learn by doing, Arduino, Raspberry Pi, Motor, Battery',
      path: 'https://shop.aeronoma.com/products/stem-kits-pro',
    },
    {
      id: 3,
      title: 'Dark Side of AI',
      content: 'Artificial Intelligence, In wrong hands, AI, Negative Sides of AI, AI in Daily Life',
      path: 'https://shop.aeronoma.com/blogs/collections/ai-can-be-dangerous-if-handled-by-wrong-hand',
    },
    {
        id: 4,
        title: 'Rise of AI',
        content: 'Rise of AI, Robotics Transformation, Daily Life AI, AI Human Bonding, AI Blogs',
        path: 'https://shop.aeronoma.com/blogs/collections/the-rise-of-ai-and-robotics-transforming-every-field-of-human-endeavor',
      },
      {
        id: 5,
        title: 'Autonomous Vehicles & Impacts On Human Life',
        content: 'Autonomous Vehicle, AV',
        path: 'https://shop.aeronoma.com/blogs/collections/autonomous-vehicles-technology-benefits-and-impact',
      },
      {
        id: 6,
        title: 'Contact',
        content: 'Contact Number, Mail, Maps, map, Direction',
        path: 'https://shop.aeronoma.com/pages/contact',
      },
      {
        id: 7,
        title: 'All Blogs',
        content: 'Blog, blogs, collection of blogs, AI blogs, Robotics Blogs',
        path: 'https://shop.aeronoma.com/blogs/collections',
      },
      {
        id: 8,
        title: 'Our Offerings In Industrial Service',
        content: 'Robot Programming, Inovative Automation Ideas, AI Based Projects, Cycle Time Optimization, Robot Simulation, PLC Programming & Controls',
        path: 'https://aeronoma.com/service.html',
      },
      {
        id: 8,
        title: 'Latest Industry Revolutionary Courses',
        content: 'Robotics Essentials, Getting Started With AI, Advanced Python, Macine Learning Basics, Python Essentials, Industrial Automation, AI courses, Python Tutorials, Robotics Courses, Industrial Automation, Industrai Robots',
        path: 'https://edu.aeronoma.com',
      },
      {
        id: 9,
        title: 'Robo Dog',
        content: 'Robot Dog, Aero-Puppy, RoboDog',
        path: 'https://shop.aeronoma.com/products/aero-puppy',
      },
      {
        id: 10,
        title: 'AeroBot- A Humanoid Robot by Aeronoma',
        content: 'Humanoid Robot, RoboCop, Human bot, multipurpose robot, Helpful Robot',
        path: 'https://shop.aeronoma.com/products/aerobot',
      },
      {
        id: 11,
        title: 'Aerobot: A Service Robot',
        content: 'Service Robot, Restaurant Robot, Hospital Robot, Multi Purpose, Warehouse',
        path: 'https://shop.aeronoma.com/products/aerobot-by-aeronoma',
      },
      {
        id: 12,
        title: 'Aeromove(AMR)',
        content: 'AMR',
        path: 'https://shop.aeronoma.com/products/aeromove',
      },
      {
        id: 13,
        title: 'Aerov1',
        content: 'AGV, Multipurpose AGV, Vegetables, Warehouse, Agriculture, Shopping Mall',
        path: 'https://shop.aeronoma.com/products/aerov1',
      },
      {
        id: 14,
        title: 'Industrial Robot',
        content: 'Industrial Robot, Robotic Arm, Maps, map, Direction',
        path: 'https://shop.aeronoma.com/products/industrial-robot',
      },
      {
        id: 15,
        title: 'Basic STEM Kit',
        content: 'STEM kit, Paper LED, Kid Robots, kids, Science Project',
        path: 'https://shop.aeronoma.com/products/stem-kit-basic',
      },
      {
        id: 16,
        title: 'Lgin & Sign Up',
        content: 'Login, SignUp, Account, Profile',
        path: 'https://shop.aeronoma.com/account',
      },
      {
        id: 17,
        title: 'WishList',
        content: 'Liked Products, Wishlist',
        path: 'https://shop.aeronoma.com/pages/wish-list',
      },
      {
        id: 18,
        title: 'My Cart',
        content: 'Add to cart, Checkout, Order Confirmation',
        path: 'https://shop.aeronoma.com/cart',
      },

    // Add more items as needed
  ];
  
  export default data;
  