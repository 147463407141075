import React, { useState } from 'react'
import Search from '../components/Search';
import myImage from './images/bg3.png'
import myImage2 from './images/kidstmkt.jpeg'
import myImage4 from './images/bg2.png'
import myImg from './images/blog2pic.jpeg'
import myImg2 from './images/blog1pic.jpeg'
import myImg3 from './images/dngr.jpeg'
import myImg4 from './images/AMR1.jpeg'
import myImg5 from './images/robotdog2.jpeg'
import myImg6 from './images/servrobt.jpeg'
import myImg7 from './images/roprogram.jpeg'
import myImg8 from './images/stmktpro.jpeg'
import myImg9 from './images/agv2.jpg'
import myImg14 from './images/agv3.jpg'
import myImg10 from './images/humbot.jpeg'
import myImg17 from './images/galry.png'
import { Helmet } from 'react-helmet'
import SectionHeading from '../components/section-heading'
import CategoryCard from '../components/category-card'
import ItemCard from '../components/item-card'
import BlogPostCard from '../components/blog-post-card'
import './home.css'

const Home = (props) => {
  const [showPopup, setShowPopup] = useState(false);
  const togglePopup = () => {
    setShowPopup(!showPopup); 
  };

  return (
    <div className="home-container10">
      <Helmet>
        <title>Aeronoma Robotics || Unlocking New Horizons </title>
        <meta property="og:title" content="Aeronoma Robotics || Where Revolutions Starts" />
      </Helmet>
      <div className="home-navbar1">
        <header data-role="Header" className="home-header max-width-container">
          <div className="home-navbar2">
            <div className="home-container11">
              <img
                alt="h3271286"
                src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMjQnIGhlaWdodD0nMjQnIHZpZXdCb3g9JzAgMCAyNCAyNCcgZmlsbD0nbm9uZScgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz4KPHBhdGggZmlsbC1ydWxlPSdldmVub2RkJyBjbGlwLXJ1bGU9J2V2ZW5vZGQnIGQ9J00xOC4zMTkgMTQuNDMyNkMyMC43NjI4IDExLjI5NDEgMjAuNTQyIDYuNzUzNDQgMTcuNjU2OSAzLjg2ODI2QzE0LjUzMjcgMC43NDQwNjcgOS40NjczNCAwLjc0NDA2NyA2LjM0MzE1IDMuODY4MjZDMy4yMTg5NSA2Ljk5MjQ2IDMuMjE4OTUgMTIuMDU3OCA2LjM0MzE1IDE1LjE4MkM5LjIyODMzIDE4LjA2NzIgMTMuNzY5IDE4LjI4NzkgMTYuOTA3NSAxNS44NDQyQzE2LjkyMSAxNS44NTk0IDE2LjkzNTEgMTUuODc0NCAxNi45NDk3IDE1Ljg4OTFMMjEuMTkyNCAyMC4xMzE3QzIxLjU4MjkgMjAuNTIyMiAyMi4yMTYxIDIwLjUyMjIgMjIuNjA2NiAyMC4xMzE3QzIyLjk5NzEgMTkuNzQxMiAyMi45OTcxIDE5LjEwOCAyMi42MDY2IDE4LjcxNzVMMTguMzY0IDE0LjQ3NDlDMTguMzQ5MyAxNC40NjAyIDE4LjMzNDMgMTQuNDQ2MSAxOC4zMTkgMTQuNDMyNlpNMTYuMjQyNiA1LjI4MjQ4QzE4LjU4NTggNy42MjU2MiAxOC41ODU4IDExLjQyNDYgMTYuMjQyNiAxMy43Njc4QzEzLjg5OTUgMTYuMTEwOSAxMC4xMDA1IDE2LjExMDkgNy43NTczNiAxMy43Njc4QzUuNDE0MjEgMTEuNDI0NiA1LjQxNDIxIDcuNjI1NjIgNy43NTczNiA1LjI4MjQ4QzEwLjEwMDUgMi45MzkzMyAxMy44OTk1IDIuOTM5MzMgMTYuMjQyNiA1LjI4MjQ4WicgZmlsbD0nYmxhY2snLz4KPC9zdmc+Cg=="
                className="home-image1"
              />
              <Search />
            </div>
            <div className="home-middle1">
              <div className="home-left1">
                <span className="navbar-link"><a href="https://shop.aeronoma.com">SHOP</a></span>
                <span className="navbar-link"><a href="https://edu.aeronoma.com">EDUCATION</a></span>
                <span className="navbar-link"><a href="/service.html">SERVICE</a></span>
              </div>
              <span className="navbar-logo-title"><a href="https://aeronoma.com">AERONOMA</a></span>
              <div className="home-right1">
                <span className="navbar-link"><a href="https://shop.aeronoma.com/blogs/collections">BLOG</a> </span>
                <span className="navbar-link" onClick={togglePopup}>ABOUT</span> {/* Toggle popup */}
                <span className="navbar-link"><a href="https://shop.aeronoma.com/pages/contact">CONTACT</a></span>
              </div>
            </div>
            <div className="home-icons">
              <a href= "https://shop.aeronoma.com/cart">
              <img 
                alt="iconsbxscart3271299"
                src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMjQnIGhlaWdodD0nMjQnIHZpZXdCb3g9JzAgMCAyNCAyNCcgZmlsbD0nbm9uZScgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz4KPHBhdGggZD0nTTIxLjgyMiA3LjQzMUMyMS42MzUgNy4xNjEgMjEuMzI4IDcgMjEgN0g3LjMzM0w2LjE3OSA0LjIzQzUuODY3IDMuNDgyIDUuMTQzIDMgNC4zMzMgM0gyVjVINC4zMzNMOS4wNzcgMTYuMzg1QzkuMjMyIDE2Ljc1NyA5LjU5NiAxNyAxMCAxN0gxOEMxOC40MTcgMTcgMTguNzkgMTYuNzQxIDE4LjkzNyAxNi4zNTJMMjEuOTM3IDguMzUyQzIyLjA1MiA4LjA0NCAyMi4wMDkgNy43IDIxLjgyMiA3LjQzMVonIGZpbGw9JyMxNjE2MTYnLz4KPHBhdGggZD0nTTEwLjUgMjFDMTEuMzI4NCAyMSAxMiAyMC4zMjg0IDEyIDE5LjVDMTIgMTguNjcxNiAxMS4zMjg0IDE4IDEwLjUgMThDOS42NzE1NyAxOCA5IDE4LjY3MTYgOSAxOS41QzkgMjAuMzI4NCA5LjY3MTU3IDIxIDEwLjUgMjFaJyBmaWxsPScjMTYxNjE2Jy8+CjxwYXRoIGQ9J00xNy41IDIxQzE4LjMyODQgMjEgMTkgMjAuMzI4NCAxOSAxOS41QzE5IDE4LjY3MTYgMTguMzI4NCAxOCAxNy41IDE4QzE2LjY3MTYgMTggMTYgMTguNjcxNiAxNiAxOS41QzE2IDIwLjMyODQgMTYuNjcxNiAyMSAxNy41IDIxWicgZmlsbD0nIzE2MTYxNicvPgo8L3N2Zz4K"
                className="home-image2"
              />
              </a>
              <a href="https://shop.aeronoma.com/pages/wish-list">
              <img
                alt="iconsbxsheartcircle3271300"
                src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMjQnIGhlaWdodD0nMjQnIHZpZXdCb3g9JzAgMCAyNCAyNCcgZmlsbD0nbm9uZScgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz4KPHBhdGggZD0nTTEyIDJDNi40ODYgMiAyIDYuNDg2IDIgMTJDMiAxNy41MTQgNi40ODYgMjIgMTIgMjJDMTcuNTE0IDIyIDIyIDE3LjUxNCAyMiAxMkMyMiA2LjQ4NiAxNy41MTQgMiAxMiAyWk0xNi4xODYgMTIuNzRMMTIgMTYuOTI2TDcuODE0IDEyLjc0QzYuNzI1IDExLjY1MiA2LjcyNSA5LjkyNyA3LjgxNCA4LjgzM0M4LjkwOCA3Ljc0NSAxMC42MzIgNy43NDUgMTEuNzIgOC44MzNMMTIgOS4xMTJMMTIuMjc5IDguODMzQzEzLjM2NyA3Ljc0NSAxNS4wOTIgNy43NDUgMTYuMTg1IDguODMzQzE3LjI3NCA5LjkyNyAxNy4yNzQgMTEuNjUxIDE2LjE4NiAxMi43NFonIGZpbGw9JyMxNjE2MTYnLz4KPC9zdmc+Cg=="
                className="home-image3"
              />
              </a>
              <a href="https://shop.aeronoma.com/account/">
              <img
                alt="AccountCircle3271301"
                src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMjQnIGhlaWdodD0nMjQnIHZpZXdCb3g9JzAgMCAyNCAyNCcgZmlsbD0nbm9uZScgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz4KPHBhdGggZD0nTTEyIDJDNi40OCAyIDIgNi40OCAyIDEyQzIgMTcuNTIgNi40OCAyMiAxMiAyMkMxNy41MiAyMiAyMiAxNy41MiAyMiAxMkMyMiA2LjQ4IDE3LjUyIDIgMTIgMlpNMTIgNUMxMy42NiA1IDE1IDYuMzQgMTUgOEMxNSA5LjY2IDEzLjY2IDExIDEyIDExQzEwLjM0IDExIDkgOS42NiA5IDhDOSA2LjM0IDEwLjM0IDUgMTIgNVpNMTIgMTkuMkM5LjUgMTkuMiA3LjI5IDE3LjkyIDYgMTUuOThDNi4wMyAxMy45OSAxMCAxMi45IDEyIDEyLjlDMTMuOTkgMTIuOSAxNy45NyAxMy45OSAxOCAxNS45OEMxNi43MSAxNy45MiAxNC41IDE5LjIgMTIgMTkuMlonIGZpbGw9JyMxNjE2MTYnLz4KPC9zdmc+Cg=="
                className="home-image4"
              />
              </a>
            </div>
          </div>
          <div data-role="BurgerMenu" className="home-burger-menu">
            <svg viewBox="0 0 1024 1024" className="home-icon10">
              <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </div>
          <div data-role="MobileMenu" className="home-mobile-menu">
            <div className="home-nav">
              <div className="home-container12">
                <span className="home-logo-center2"><a href="https://aeronoma.com">AERONOMA</a></span>
                <div
                  data-role="CloseMobileMenu"
                  className="home-close-mobile-menu"
                >
                  <svg viewBox="0 0 1024 1024" className="home-icon12">
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center'  }}>
                  <img
                     alt="h3271286"
                     src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMjQnIGhlaWdodD0nMjQnIHZpZXdCb3g9JzAgMCAyNCAyNCcgZmlsbD0nbm9uZScgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz4KPHBhdGggZmlsbC1ydWxlPSdldmVub2RkJyBjbGlwLXJ1bGU9J2V2ZW5vZGQnIGQ9J00xOC4zMTkgMTQuNDMyNkMyMC43NjI4IDExLjI5NDEgMjAuNTQyIDYuNzUzNDQgMTcuNjU2OSAzLjg2ODI2QzE0LjUzMjcgMC43NDQwNjcgOS40NjczNCAwLjc0NDA2NyA2LjM0MzE1IDMuODY4MjZDMy4yMTg5NSA2Ljk5MjQ2IDMuMjE4OTUgMTIuMDU3OCA2LjM0MzE1IDE1LjE4MkM5LjIyODMzIDE4LjA2NzIgMTMuNzY5IDE4LjI4NzkgMTYuOTA3NSAxNS44NDQyQzE2LjkyMSAxNS44NTk0IDE2LjkzNTEgMTUuODc0NCAxNi45NDk3IDE1Ljg4OTFMMjEuMTkyNCAyMC4xMzE3QzIxLjU4MjkgMjAuNTIyMiAyMi4yMTYxIDIwLjUyMjIgMjIuNjA2NiAyMC4xMzE3QzIyLjk5NzEgMTkuNzQxMiAyMi45OTcxIDE5LjEwOCAyMi42MDY2IDE4LjcxNzVMMTguMzY0IDE0LjQ3NDlDMTguMzQ5MyAxNC40NjAyIDE4LjMzNDMgMTQuNDQ2MSAxOC4zMTkgMTQuNDMyNlpNMTYuMjQyNiA1LjI4MjQ4QzE4LjU4NTggNy42MjU2MiAxOC41ODU4IDExLjQyNDYgMTYuMjQyNiAxMy43Njc4QzEzLjg5OTUgMTYuMTEwOSAxMC4xMDA1IDE2LjExMDkgNy43NTczNiAxMy43Njc4QzUuNDE0MjEgMTEuNDI0NiA1LjQxNDIxIDcuNjI1NjIgNy43NTczNiA1LjI4MjQ4QzEwLjEwMDUgMi45MzkzMyAxMy44OTk1IDIuOTM5MzMgMTYuMjQyNiA1LjI4MjQ4WicgZmlsbD0nYmxhY2snLz4KPC9zdmc+Cg=="
                     className="home-image1"
                  />
                  <Search />
              </div>
              <div className="home-middle2">             
                <span className="navbar-link"><a href="https://shop.aeronoma.com">SHOP</a></span>
                <span className="navbar-link"><a href="https://edu.aeronoma.com">EDUCATION</a></span>
                <span className="navbar-link"><a href="/service.html">SERVICE</a></span>
                <span className="navbar-link" onClick={togglePopup}>ABOUT</span> {/* Toggle popup */}
                <span className="navbar-link"><a href="https://shop.aeronoma.com/blogs/collections">BLOG</a></span>
                <span className="navbar-link"><a href="https://shop.aeronoma.com/pages/contact">CONTACT</a></span>
              <div style={{ display: 'flex', gap: '10px', alignItems: 'center'}}>
                <a href= "https://shop.aeronoma.com/cart">
                  <img 
                    alt="iconsbxscart3271299"
                    src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMjQnIGhlaWdodD0nMjQnIHZpZXdCb3g9JzAgMCAyNCAyNCcgZmlsbD0nbm9uZScgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz4KPHBhdGggZD0nTTIxLjgyMiA3LjQzMUMyMS42MzUgNy4xNjEgMjEuMzI4IDcgMjEgN0g3LjMzM0w2LjE3OSA0LjIzQzUuODY3IDMuNDgyIDUuMTQzIDMgNC4zMzMgM0gyVjVINC4zMzNMOS4wNzcgMTYuMzg1QzkuMjMyIDE2Ljc1NyA5LjU5NiAxNyAxMCAxN0gxOEMxOC40MTcgMTcgMTguNzkgMTYuNzQxIDE4LjkzNyAxNi4zNTJMMjEuOTM3IDguMzUyQzIyLjA1MiA4LjA0NCAyMi4wMDkgNy43IDIxLjgyMiA3LjQzMVonIGZpbGw9JyMxNjE2MTYnLz4KPHBhdGggZD0nTTEwLjUgMjFDMTEuMzI4NCAyMSAxMiAyMC4zMjg0IDEyIDE5LjVDMTIgMTguNjcxNiAxMS4zMjg0IDE4IDEwLjUgMThDOS42NzE1NyAxOCA5IDE4LjY3MTYgOSAxOS41QzkgMjAuMzI4NCA5LjY3MTU3IDIxIDEwLjUgMjFaJyBmaWxsPScjMTYxNjE2Jy8+CjxwYXRoIGQ9J00xNy41IDIxQzE4LjMyODQgMjEgMTkgMjAuMzI4NCAxOSAxOS41QzE5IDE4LjY3MTYgMTguMzI4NCAxOCAxNy41IDE4QzE2LjY3MTYgMTggMTYgMTguNjcxNiAxNiAxOS41QzE2IDIwLjMyODQgMTYuNjcxNiAyMSAxNy41IDIxWicgZmlsbD0nIzE2MTYxNicvPgo8L3N2Zz4K"
                    className="home-image2"
                 />
                </a>
                <a href="https://shop.aeronoma.com/pages/wish-list">
                  <img
                    alt="iconsbxsheartcircle3271300"
                    src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMjQnIGhlaWdodD0nMjQnIHZpZXdCb3g9JzAgMCAyNCAyNCcgZmlsbD0nbm9uZScgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz4KPHBhdGggZD0nTTEyIDJDNi40ODYgMiAyIDYuNDg2IDIgMTJDMiAxNy41MTQgNi40ODYgMjIgMTIgMjJDMTcuNTE0IDIyIDIyIDE3LjUxNCAyMiAxMkMyMiA2LjQ4NiAxNy41MTQgMiAxMiAyWk0xNi4xODYgMTIuNzRMMTIgMTYuOTI2TDcuODE0IDEyLjc0QzYuNzI1IDExLjY1MiA2LjcyNSA5LjkyNyA3LjgxNCA4LjgzM0M4LjkwOCA3Ljc0NSAxMC42MzIgNy43NDUgMTEuNzIgOC44MzNMMTIgOS4xMTJMMTIuMjc5IDguODMzQzEzLjM2NyA3Ljc0NSAxNS4wOTIgNy43NDUgMTYuMTg1IDguODMzQzE3LjI3NCA5LjkyNyAxNy4yNzQgMTEuNjUxIDE2LjE4NiAxMi43NFonIGZpbGw9JyMxNjE2MTYnLz4KPC9zdmc+Cg=="
                    className="home-image3"
                  />
                </a>
                <a href="https://shop.aeronoma.com/account/">
                  <img
                    alt="AccountCircle3271301"
                    src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMjQnIGhlaWdodD0nMjQnIHZpZXdCb3g9JzAgMCAyNCAyNCcgZmlsbD0nbm9uZScgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz4KPHBhdGggZD0nTTEyIDJDNi40OCAyIDIgNi40OCAyIDEyQzIgMTcuNTIgNi40OCAyMiAxMiAyMkMxNy41MiAyMiAyMiAxNy41MiAyMiAxMkMyMiA2LjQ4IDE3LjUyIDIgMTIgMlpNMTIgNUMxMy42NiA1IDE1IDYuMzQgMTUgOEMxNSA5LjY2IDEzLjY2IDExIDEyIDExQzEwLjM0IDExIDkgOS42NiA5IDhDOSA2LjM0IDEwLjM0IDUgMTIgNVpNMTIgMTkuMkM5LjUgMTkuMiA3LjI5IDE3LjkyIDYgMTUuOThDNi4wMyAxMy45OSAxMCAxMi45IDEyIDEyLjlDMTMuOTkgMTIuOSAxNy45NyAxMy45OSAxOCAxNS45OEMxNi43MSAxNy45MiAxNC41IDE5LjIgMTIgMTkuMlonIGZpbGw9JyMxNjE2MTYnLz4KPC9zdmc+Cg=="
                    className="home-image4"
                  />
                </a>
                </div>
              </div>
            </div>
            <div className="icon-links2">
               <a href="https://www.instagram.com/aeronoma.official/" className="iconlink2">
                  <i className="fa-brands fa-instagram" ></i>
                  </a>
                  <a href="https://www.facebook.com/aeronoma" className="iconlink2">
                  <i class="fa-brands fa-facebook-f"></i>
                  </a>
                  <a href="https://www.x.com/aeronoma" className="iconlink2">
                    <i className="fa-brands fa-x"></i>
                  </a>
                  <a href="https://www.linkedin.com/in/aeronoma" className="iconlink2">
                  <i class="fa-brands fa-linkedin-in"></i>
                  </a>
                  <a href="https://wa.me/8527037470" className="iconlink2" target="_blank" rel="noopener noreferrer">
                  <i class="fa-brands fa-whatsapp"></i>
                  </a>
              </div>
          </div>
        </header>
      </div>

      {showPopup && (
        <div className="popup-overlay" onClick={togglePopup}>
          <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            <div className="popup-inner">
              <img src={myImg17} alt="Description" className="popup-image" />
              <div className="popup-text">
                <h2 className='about'>About Us</h2>
                
                <p>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  Hey! Welcome to Aeronoma, a leader in automation & robotics with over 12 yrs of expertise. We specialize in industrial automation services across various robotic applications, featuring renowned international brands like ABB, FANUC, Kawasaki, Motoman, Omron, Mitsubishi, and Panasonic.
                </p>
                <br />
                <p>
                  &nbsp;&nbsp;&nbsp;Our customized AGVs and AMRs serve diverse sectors, like restaurants & hospitals. Our educational initiatives empower students & professionals with Robotics and AI training, enhancing careers and inspiring innovation. Join us to shape the future of automation!
                </p>
                <div className="popup-links">
                  <a href="https://aeronoma.com" className="popup-link">
                    <i className="fas fa-home"></i>
                  </a>
                  <a href="https://shop.aeronoma.com" className="popup-link">
                    <i className="fa-solid fa-cart-shopping"></i>
                  </a>
                  <a href="https://edu.aeronoma.com" className="popup-link">
                    <i className="fas fa-graduation-cap"></i>
                  </a>
                </div>
                <button className="close-btn" onClick={togglePopup}>Close</button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="home-main">
        <div className="home-hero1 section-container">
          <div className="home-max-width1 max-width-container">
            <div className="home-hero2">
              <div className="home-container13">
                <div className="home-info">
                  <img
                    alt="Rectangle43271305"
                    src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMicgaGVpZ2h0PSc1Micgdmlld0JveD0nMCAwIDIgNTInIGZpbGw9J25vbmUnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+CjxyZWN0IHdpZHRoPScyJyBoZWlnaHQ9JzUyJyBmaWxsPSdibGFjaycgZmlsbC1vcGFjaXR5PScwLjUnLz4KPC9zdmc+Cg=="
                    className="home-image5"
                  />
                  <span className="home-text22">
                    <span>Playing with Robots</span>
                    <br></br>
                    <span>Since - 2012</span>
                  </span>
                </div>
                <h1 className="home-text26 Heading-1"> Get Your Robot
                  <span style={{ display: 'block', marginTop: '8px', letterSpacing: '0.7em' }}></span></h1>
                <div className="home-container14">
                  <span className="home-text27">Starting from</span>
                  <br />
                  <span className="home-text28">₹19779</span>
                </div>
                <div className="home-btn-group">
                  <button className="button"><a href="https://shop.aeronoma.com/">Know More</a></button>
                </div>
              </div>
              <img
                alt="image23271449"
                src={myImage}
                className="home-image6"
              />
            </div>
          </div>
        </div>
        <div className="section-container column">
          <div className="max-width-container">
            <SectionHeading
              heading="SHOP BY CATEGORIES"
              subtitle="Start shopping based on the categories you are interested in"
            ></SectionHeading>
            <div className="home-cards-container">
              <CategoryCard
               categoryImg={myImg6}
               ></CategoryCard>
              {/* <CategoryCard name="Home Automation"></CategoryCard> */}
              <CategoryCard
                name="AGV"
                categoryImg={myImg9}
                linkTo="https://shop.aeronoma.com/products/aerov1"
              ></CategoryCard>
              <CategoryCard
                name="Industrial Robot"
                categoryImg={myImg7}
                linkTo="https://shop.aeronoma.com/products/industrial-robot"
              ></CategoryCard>
              <CategoryCard
                name="AMR"
                categoryImg={myImg4}
                linkTo="https://shop.aeronoma.com/products/aeromove"
              ></CategoryCard>
              <CategoryCard
                name="Robot Dog"
                categoryImg={myImg5}
                linkTo="https://shop.aeronoma.com/products/aero-puppy"
              ></CategoryCard>
              <CategoryCard
                name="Humanoid"
                categoryImg={myImg10}
              ></CategoryCard>
            </div>
          </div>
          <div className="home-banner">
            <div className="home-container15">
              <h3 className="home-text29 Heading-3">AERONOMA</h3>
              <span className="home-text30">
                <span></span>
                <span>ROBOTICS</span>
              </span>
            </div>
          </div>
          <div className="home-container16 max-width-container">
            <div className="home-container17"
              style={{
                // width: 'auto',
                height: 'auto',
                display: 'flex',
                position: 'relative',
                flexDirection: 'column',
                alignItems: 'left',
                justifyContent: 'left',
                backgroundPosition: 'left',
                color: 'white',
                textAlign: 'left',
              }}
            >
              <span className="home-text33">
                <span>
                  <span><br /></span>
                  Welcome to Aeronoma, where we are shaping the future of automation and artificial intelligence. As pioneers in the field, we offer...
                </span>
              </span>
              <button className="button"onClick={togglePopup}>Read More</button>
              <span><br /></span>
            </div>
          </div>
        </div>
        <div className="section-container">
          <div className="max-width-container">
            <SectionHeading></SectionHeading>
            <div className="home-gallery">
              <div className="home-left2">
                <ItemCard
                  name="AeroBot by Aeronoma"
                  value="2,49,900"
                  imageSrc={myImg6}
                  linkTo="https://shop.aeronoma.com/products/aerobot-by-aeronoma"
                  rootClassName="rootClassName4"
                ></ItemCard>
              </div>
              <div className="home-right2">
                <div className="home-top">
                  <div className="home-left3">
                    <ItemCard
                      name="Aero Puppy"
                      imageSrc={myImg5}
                      rootClassName="rootClassName2"
                      linkTo="https://shop.aeronoma.com/products/aero-puppy"
                    ></ItemCard>
                  </div>
                  <div className="home-right3">
                    <ItemCard
                      name="Stem Kit Basic"
                      value="999"
                      imageSrc={myImage2}
                      rootClassName="rootClassName3"
                      linkTo="https://shop.aeronoma.com/products/stem-kit-basic"
                    ></ItemCard>
                  </div>
                </div>
                <div className="home-bottom">
                  <div className="home-left4">
                    <ItemCard
                      name="STEM Kits Pro"
                      value="4,499"
                      imageSrc={myImg8}
                      rootClassName="item-cardroot-class-name1"
                      linkTo="https://shop.aeronoma.com/products/stem-kits-pro"
                    ></ItemCard>
                  </div>
                  <div className="home-right4">
                    <ItemCard
                      name="AGV"
                      value="37,999"
                      imageSrc={myImg14}
                      rootClassName="item-cardroot-class-name6"
                      linkTo="https://shop.aeronoma.com/products/aerov1"
                    ></ItemCard>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="home-full-width-banner section-container">
          <div className="home-left5">
            <div className="home-content">
              <span className="home-text39">Unlock AI Potential with Aeronoma </span>
              <span className="home-text40">
                Enhance your resume with state-of-the-art AI skills!
              </span>
            </div>
            <div className="home-btn button">
              <span className="home-text41"><a href="https://edu.aeronoma.com">Enroll Now</a></span>
            </div>
          </div>
          <img
            alt="Rectangle13271410"
            src={myImage4}
            className="home-image7"

          />
        </div>
        <div className="section-container">
          <div className="max-width-container">
            <SectionHeading
              heading="Our blog"
              subtitle="Read the latest news of Robotics and AI related articles"
              rootClassName="section-headingroot-class-name"
            >

            </SectionHeading>
            <div className="home-container18">
              <BlogPostCard
                imageSrc={myImg}
                rootClassName="blog-post-cardroot-class-name"
              ></BlogPostCard>
              <BlogPostCard
                title="<br />The Rise of AI and Robotics: Transforming Every Field of Human Endeavor"
                newProp="The Rise of AI and Robotics: Transforming Every Field of Human Endeavor"
                imageSrc={myImg2}
                description='The rise of AI and robotics is ushering in a new era of innovation...'
                linkTo='https://shop.aeronoma.com/blogs/collections/the-rise-of-ai-and-robotics-transforming-every-field-of-human-endeavor'
              ></BlogPostCard>
              <BlogPostCard
                title="AI can be dengerious if handled by wrong hand"
                newProp="AI can be dengerious if handled by wrong hand"
                imageSrc={myImg3}
                description="One of the most pressing dangers of AI lies in its potential for misuse in..."
                linkTo='https://shop.aeronoma.com/blogs/collections/ai-can-be-dangerous-if-handled-by-wrong-hand'
              ></BlogPostCard>
            </div>
          </div>
        </div>
      </div>
      <div className="home-footer1">
        <div className="max-width-container">
          <footer className="home-footer2">
            <div className="home-container19">
              <h3 className="home-text42 Heading-3"><a href="https://aeronoma.com">AERONOMA</a></h3>
              <span className="home-text43">
                <span>10/10A, Ashok Vihar Phase 3 Ext. Gurugram, Haryana 122001,</span>
                <br></br>
                <span>India</span>
              </span>
              <span className="home-text46">(+91) 85270-37470</span>
              <span> <a href="mailto:contact@aeronoma.com" className="home-text47">contact@aeronoma.com</a></span>
               <div className="icon-links2">
               <a href="https://www.instagram.com/aeronoma.official/" className="iconlink2">
                  <i className="fa-brands fa-instagram" ></i>
                  </a>
                  <a href="https://www.facebook.com/aeronoma" className="iconlink2">
                  <i class="fa-brands fa-facebook-f"></i>
                  </a>
                  <a href="https://www.x.com/aeronoma" className="iconlink2">
                    <i className="fa-brands fa-x"></i>
                  </a>
                  <a href="https://www.linkedin.com/in/aeronoma" className="iconlink2">
                  <i class="fa-brands fa-linkedin-in"></i>
                  </a>
                  <a href="https://wa.me/8527037470" className="iconlink2" target="_blank" rel="noopener noreferrer">
                  <i class="fa-brands fa-whatsapp"></i>
                  </a>
              </div>
            </div>

            <div className="home-links-container">
              <div className="home-container20">
                <span className="home-text48">Categories</span>
                <span className="home-text49"><a href="https://shop.aeronoma.com/products/industrial-robot">Industrial Robot</a></span>
                <span className="home-text50"><a href="https://shop.aeronoma.com/products/aerobot-by-aeronoma">Service Robot</a></span>
                <span className="home-text51"><a href="https://shop.aeronoma.com">Home Automation</a></span>
                <span className="home-text52"><a href="https://shop.aeronoma.com/products/stem-kit-basic">STEM Kits</a></span>
                <span className="home-text53"><a href="https://shop.aeronoma.com/products/aeromove">AGV/AMR</a></span>
                <span className="home-text54"><a href="https://shop.aeronoma.com/products/aerobot">Humanoid</a></span>
              </div>
              <div className="home-container21">
                <span className="home-text55">Main Menu</span>
                <span className="home-text56"><a href="https://shop.aeronoma.com">Shop</a></span>
                <span className="home-text57"><a href="https://edu.aeronoma.com">Education</a></span>
                <span className="home-text58"><a href="/service.html">Service</a></span>
                <span className="home-text59"onClick={togglePopup}>About</span>
                <span className="home-text60"><a href="https://shop.aeronoma.com/blogs/collections">Blog</a></span>
              </div>
              <div className="home-container22">
                <span className="home-text61">Resources</span>
                <span className="home-text62"><a href="https://shop.aeronoma.com/pages/contact">Contact us</a></span>
                <span className="home-text63"><a href="https://shop.aeronoma.com/account">Order</a></span>
                <span className="home-text64"><a href="https://shop.aeronoma.com/account">Track your order</a></span>
                <span className="home-text65"><a href="https://shop.aeronoma.com/account">Shipping &amp; Delivery</a></span>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>

  )
}

export default Home
